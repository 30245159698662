import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import type { SendParams } from '~/types/Web3Provider';
import useSendContractMethod from './useSendContractMethod';
import { useEnvs, useMainStore } from '#imports';
import { parseUnits } from 'ethers';
import { Tokens, tokensConfig } from '~/utils/constants';

type Web3Confirmation = (confirmations?: number) => void;

export default () => {
  const { address } = useWeb3ModalAccount();
  const { sendContractMethod } = useSendContractMethod();
  const { blockchain } = useEnvs();
  const store = useMainStore();
  const { getContract } = useAbiAccess();

  const sendContractMethodExtended = async (sendParams: SendParams, onConfirmation?: Web3Confirmation) => {
    const from = address.value;
    return sendContractMethod({ ...sendParams, from }, onConfirmation);
  };

  const approve = (token: string, quantity: number, onConfirmation?: Web3Confirmation, storeTokenName?: Tokens) => {
    const contractAddr = blockchain.contracts[storeTokenName || store.currentToken].addresses;
    const storeOrMine = storeTokenName === Tokens.pearl ? contractAddr.store : contractAddr.mine;
    return sendContractMethodExtended(
      {
        contract: 'erc20',
        address: token, // USDT
        methodName: 'approve',
        methodArguments: [storeOrMine, parseUnits(quantity.toString(), 'ether')]
      },
      onConfirmation
    );
  };

  /**
   * buyPearlPack
   * @param value - human readable value like 0.1 USDT
   * @param onConfirmation - confirmation callback, fired on each head block
   */
  const buyPack = (value: string, tokenName: Tokens, onConfirmation?: Web3Confirmation) => {
    const amount = parseUnits(value, 'ether');

    return sendContractMethodExtended(
      {
        contract: tokensConfig[tokenName].name.store,
        address: blockchain.contracts[tokenName].addresses.store,
        methodName: `buy${tokensConfig[tokenName].methodName.split(' ').join('')}Pack`,
        methodArguments: [amount]
      },
      onConfirmation
    );
  };

  const buyPackAndRegisterInReferralProgram = async (
    value: string,
    tokenName: Tokens,
    referrerAddress: string,
    onConfirmation?: Web3Confirmation
  ) => {
    const amount = parseUnits(value, 'ether');
    const storeContract = await getContract(
      tokensConfig[tokenName].name.store,
      blockchain.contracts[tokenName].addresses.store
    );
    const iface = storeContract?.interface;
    return sendContractMethodExtended(
      {
        contract: tokensConfig[tokenName].name.store,
        address: blockchain.contracts.pearl.addresses.store,
        methodName: 'multicall',
        methodArguments: [
          [
            iface?.encodeFunctionData('registerReferral', [referrerAddress]),
            iface?.encodeFunctionData(`buy${tokensConfig[tokenName].methodName.split(' ').join('')}Pack`, [amount])
          ]
        ]
      },
      onConfirmation
    );
  };

  const registerInReferral = (referrerAddress: string, onConfirmation?: Web3Confirmation) => {
    return sendContractMethodExtended(
      {
        contract: tokensConfig.pearl.name.store,
        address: blockchain.contracts.pearl.addresses.store,
        methodName: 'registerReferral',
        methodArguments: [referrerAddress]
      },
      onConfirmation
    );
  };

  /**
   * Unstake pearl on a contract
   * @param onConfirmation - confirmation callback, fired on each head block
   */
  const exit = (onConfirmation?: Web3Confirmation) => {
    return sendContractMethodExtended(
      {
        contract: tokensConfig[store.currentToken].name.store,
        address: blockchain.contracts[store.currentToken].addresses.store,
        methodName: 'exit'
      },
      onConfirmation
    );
  };

  /**
   * Unstake prearl token on a contract
   * @param value - human readable value like 0.1 prearl
   * @param onConfirmation - confirmation callback, fired on each head block
   */
  const unstake = (value: string, onConfirmation?: Web3Confirmation) => {
    const amount = parseUnits(value, 'ether');

    return sendContractMethodExtended(
      {
        contract: tokensConfig[store.currentToken].name.store,
        address: blockchain.contracts[store.currentToken].addresses.store,
        methodName: 'withdraw',
        methodArguments: [amount]
      },
      onConfirmation
    );
  };

  const claimReward = (onConfirmation?: Web3Confirmation, tokenName: Tokens = store.currentToken) => {
    return sendContractMethodExtended(
      {
        contract: tokensConfig[tokenName].name.yield,
        address: blockchain.contracts[tokenName].addresses.yield,
        methodName: 'claim'
      },
      onConfirmation
    );
  };

  const unlock = (tokenName: string, index: number, onConfirmation?: Web3Confirmation) => {
    const map: Record<string, SendParams> = {
      [tokensConfig[store.currentToken].name.contract]: {
        contract: tokensConfig[store.currentToken].name.yield,
        address: blockchain.contracts[store.currentToken].addresses.yield,
        methodName: 'unlock',
        methodArguments: [index]
      },
      [tokensConfig.blackPearl.name.contract]: {
        contract: 'blackPearlVesting',
        address: blockchain.contracts.blackPearlVesting,
        methodName: 'withdrawAvailable',
        methodArguments: [index]
      }
    };

    return sendContractMethodExtended(map[tokenName], onConfirmation);
  };

  const getReward = (onConfirmation?: Web3Confirmation) => {
    return sendContractMethodExtended(
      {
        contract: tokensConfig[store.currentToken].name.store,
        address: blockchain.contracts[store.currentToken].addresses.store,
        methodName: 'getReward'
      },
      onConfirmation
    );
  };

  return {
    approve,
    buyPack,
    exit,
    unstake,
    claimReward,
    unlock,
    getReward,
    buyPackAndRegisterInReferralProgram,
    registerInReferral
  };
};

import type { IUserModel } from '~/types/apiService';

export type TSeasonConfig = {
  seasonYear: number;
  startDate: string;
  endDate: string;
  config: TSeasonConfigItem[];
};

export interface TSeasonConfigItem {
  pointsMultiplier: number;
  tokenMultiplier: number;
  tokenAddress: string;
  seasonPoints: number;
  rate: number;
  tokenId?: number;
  tokenValue?: number;
}

export enum SeasonModalView {
  burnTokens = 'burnTokens',
  leaderboard = 'leaderboard',
  jackpot = 'jackpot',
  start = 'start',
  end = 'end',
  rewards = 'rewards'
}

export interface Reward {
  tokenAddress: string;
  amount: number;
  isNft: boolean;
}

export interface SeasonUser extends Pick<IUserModel['user'], 'address' | 'avatarUrl' | 'name'> {}

export interface SeasonsUserScores extends SeasonUser {
  points: number;
  rewards: Reward[];
}

import { CONTRACT_ABI } from './../utils/constants/tokens';
import { BrowserProvider, Contract, Interface, JsonRpcProvider } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import type { CallAbiContract } from '~/types/Web3Provider';
import useEnvs from './useEnvs';

export default () => {
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const { blockchain } = useEnvs();

  const getInterfaceDecoded = (contract: keyof typeof CONTRACT_ABI, tokenIds: string) => {
    return new Interface(CONTRACT_ABI[contract]).decodeFunctionResult('getTokenIds', tokenIds);
  };

  const getInterface = (contract: keyof typeof CONTRACT_ABI) => {
    return new Interface(CONTRACT_ABI[contract]);
  };

  const getMulticallResult = async (
    ercType: keyof typeof CONTRACT_ABI,
    tokensAddresses: string[],
    encodeMethod: string,
    userAddress: string[] = [address?.value || '']
  ): Promise<any[]> => {
    const multicallInterface = getInterface(ercType);
    const multicallContract = await getContract('multicall', blockchain.multicallAddress);
    const calldata = multicallInterface.encodeFunctionData(encodeMethod, userAddress ? userAddress : []);
    const multicallData: any = tokensAddresses.map((token: string) => [token, calldata]);
    const multicallResult = await multicallContract?.tryAggregate.staticCall(true, multicallData);

    return multicallResult;
  };

  const getContract = async (contract: keyof typeof CONTRACT_ABI, address: string) => {
    if (walletProvider.value) {
      const provider = new BrowserProvider(walletProvider.value);
      const signer = await provider.getSigner();
      return new Contract(address, CONTRACT_ABI[contract], signer);
    }
  };

  const getContractReadOnly = async (contract: keyof typeof CONTRACT_ABI, address: string) => {
    const provider = new JsonRpcProvider(blockchain.nodeUrl);

    return new Contract(address, CONTRACT_ABI[contract], provider);
  };

  const getMethodObj = async ({ contract, methodName, methodArguments = [], address }: CallAbiContract) => {
    const contractObj = await getContract(contract, address);
    const method = contractObj?.getFunction(methodName);
    return method?.populateTransaction(...methodArguments);
  };

  const getMethodCall = async ({ contract, methodName, address }: CallAbiContract) => {
    const contractObj = await getContractReadOnly(contract, address);
    const method = contractObj?.getFunction(methodName);
    return method;
  };

  return {
    getMulticallResult,
    getInterfaceDecoded,
    getInterface,
    getContract,
    getMethodObj,
    getMethodCall,
    getContractReadOnly
  };
};

<template>
  <div class="sound-control-container">
    <span style="opacity: 0.6; font-size: 20px">{{ $t(controlName) }}</span>
    <div class="control">
      <div @click="handleMute">
        <img class="muteBtn" :src="volume === 0 ? '/img/icons/mute.svg' : '/img/icons/volume.svg'" alt="" width="26" />
      </div>
      <el-slider v-model="volume" :min="0" :max="1" :show-tooltip="false" :step="0.01" />
      <span class="volumeValue">
        {{
          BigNumber(volume || 0)
            .multipliedBy(100)
            .integerValue()
            .toString()
        }}
        %
      </span>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BigNumber } from 'bignumber.js';
import { INITIAL_MUSIC_VOLUME } from '~/utils/constants';

defineProps<{ controlName: string }>();
const volume = defineModel<number>();
const volumeBeforeMute = ref(INITIAL_MUSIC_VOLUME);

const handleMute = () => {
  if (typeof volume.value !== 'number') return;

  if (volume.value > 0) {
    volumeBeforeMute.value = volume.value;
    volume.value = 0;
  } else {
    volume.value = volumeBeforeMute.value;
  }
};
</script>
<style lang="scss" scoped>
.sound-control-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.muteBtn {
  cursor: pointer;
}

.volumeValue {
  white-space: nowrap;
  color: #fff;
  font-size: 18px;
}
</style>

<style lang="scss">
.sound-control-container {
  .el-slider__runway {
    background: linear-gradient(to bottom, rgba(23, 52, 62, 1), rgba(1, 3, 3, 1));
  }

  .el-slider__bar {
    display: none;
  }

  .el-slider__button {
    width: 16px;
    height: 24px;
    border: 0;
    border-radius: 0;
    position: relative;
    background: transparent;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background-image: url('/img/icons/Rectangle 327.png');
      background-size: contain;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

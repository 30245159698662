import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import type { IUserModel } from '~/types/apiService';
import { apiUrls } from '~/utils/constants';
import { useMainStore } from '~/stores/mainStore';

const HEAD_OF_SALES = '1061';

export default defineNuxtPlugin({
  dependsOn: ['web3-modal'],
  hooks: {
    'vue:setup'() {
      const store = useMainStore();
      const { address } = useWeb3ModalAccount();
      const { apiUrl } = useEnvs();
      const route = useRoute();
      const refId = useCookie('refId', { default: () => HEAD_OF_SALES });

      useFetch<IUserModel>(apiUrls.v1.user, {
        baseURL: apiUrl,
        watch: [address, () => route.query.ref],
        query: {
          address: address
        },
        onResponse: ({ response }) => {
          if (!response.ok && route.query.ref) {
            refId.value = route.query.ref.toString();
          }

          if (response.ok) {
            store.setUserData(response._data);
          }
        },
        key: 'userData'
      });
    }
  }
});

import pearl from './pearl.png';
import coal from './coal.png';
import gold from './gold.png';
import goldOre from './goldOre.png';
import iron from './iron.png';
import ironOre from './ironOre.png';
import palladium from './palladium.png';
import platina from './platina.png';
import platinaOre from './platinaOre.png';
import rhodium from './rhodium.png';
import silver from './silver.png';
import silverOre from './silverOre.png';
import wood from './wood.png';
import nail from './nail.png';
import acorn from './acorn.png';
import shiningMetalite from './shining_metallite.png';
import crystallineOre from './crystalline_ore.png';
import heatinglog from './heatinglogs.png';
import hammer from './hammer.png';
import woodenPlank from './woodenPlank.png';
import seasonPoints from './seasonPoints.png';
import earrings from './earrings.png';
import necklaces from './necklaces.png';
import rings from './rings.png';
import palmCommon from './palmCommon.png';
import palmUncommon from './palmUncommon.png';
import palmRare from './palmRare.png';
import chairCommon from './chairCommon.png';
import chairUncommon from './chairUncommon.png';
import chairRare from './chairRare.png';
import pickaxe from './pickaxe.png';
import shovel from './shovel.png';
import saw from './saw.png';
import bed from './bed.png';
import table from './table.png';
import blackPearl from './blackPearl.png';
import commonBox from './commonBox.png';
import uncommonBox from './uncommonBox.png';
import rareBox from './rareBox.png';
import barrelCommon from './barrelCommon.png';
import barrelUncommon from './barrelUncommon.png';
import ringsAndMore from './ringsAndMore.png';
import woodenPlateAcorns from './woodenPlateAcorns.png';
import woodenPlateLeaves from './woodenPlateLeaves.png';

import broom from './broom.png';
import leaf from './leaf.png';
import metalJoin from './metalJoin.png';
import glass from './glass.png';
import brick from './brick.png';
import clay from './clay.png';
import sand from './sand.png';

const ship1 = 'https://static.pearldivergame.com/staging/output/ships-00.gif';
const ship2 = 'https://static.pearldivergame.com/staging/output/ships-01.gif';
const ship3 = 'https://static.pearldivergame.com/staging/output/ships-02.gif';
const ship4 = 'https://static.pearldivergame.com/staging/output/ships-03.gif';
const ship5 = 'https://static.pearldivergame.com/staging/output/ships-04.gif';
const ship6 = 'https://static.pearldivergame.com/staging/output/ships-05.gif';
const ship7 = 'https://static.pearldivergame.com/staging/output/ships-06.gif';
import ship8 from './ship8.png';
import ship9 from './ship9.png';
import ship10 from './ship10.png';
import usdt from './usdt.png';

import littleHut from './LittleHut.png';
import mediumHut from './MediumHut.png';
import largerHut from './LargerHut.png';
import greatHut from './GreatHut.png';
import speciousHut from './SpeciousHut.png';

import { Tokens, TokenUniquenessType } from '~/utils/constants';

enum ExtraTokens {
  usdt = 'usdt'
}

enum TokensUniqueness {
  chairs = 'chairs',
  palms = 'palms',
  jewelryBoxes = 'jewelryBoxes',
  barrelsOfRum = 'barrelsOfRum'
}

enum TokensMappings {
  ships = 'ships',
  woodenHuts = 'woodenHuts'
}

type ComplexToken = { [key: number]: string };

export type TokenIconUniquenessType = Record<TokenUniquenessType, string>;

type TTokenIconValue<K> = K extends Tokens | ExtraTokens
  ? string
  : K extends TokensMappings
    ? string[]
    : TokenIconUniquenessType;

export type TokenIconType = {
  [K in Tokens | ExtraTokens | TokensUniqueness | TokensMappings]: TTokenIconValue<K>;
};

const tokens: TokenIconType = {
  pearl,
  coal,
  gold,
  goldOre,
  iron,
  ironOre,
  palladium,
  platina,
  platinaOre,
  rhodium,
  silver,
  silverOre,
  wood,
  seasonPoints,
  blackPearl,
  earrings,
  rings,
  necklaces,
  usdt,
  nail,
  acorn,
  shiningMetalite,
  crystallineOre,
  heatinglog,
  hammer,
  woodenPlank,
  pickaxe,
  shovel,
  saw,
  bed,
  table,
  broom,
  leaf,
  metalJoin,
  glass,
  brick,
  clay,
  sand,
  ships: [ship1, ship2, ship3, ship4, ship5, ship6, ship7, ship8, ship9, ship10],
  woodenHuts: [littleHut, mediumHut, largerHut, greatHut, speciousHut],

  palms: {
    common: palmCommon,
    uncommon: palmUncommon,
    rare: palmRare
  },
  chairs: {
    common: chairCommon,
    uncommon: chairUncommon,
    rare: chairRare
  },
  jewelryBoxes: {
    common: commonBox,
    uncommon: uncommonBox,
    rare: rareBox
  },
  barrelsOfRum: {
    common: barrelCommon,
    uncommon: barrelUncommon,
    rare: ''
  }
};

const complexTokensTestnet: ComplexToken = {
  17: ringsAndMore,
  18: ringsAndMore,
  19: ringsAndMore,
  21: woodenPlateAcorns,
  68: woodenPlateLeaves,
  26: chairCommon,
  27: chairUncommon,
  28: chairRare,
  29: palmCommon,
  30: palmUncommon,
  31: palmRare,
  32: ship1,
  33: ship2,
  34: ship3,
  35: ship4,
  36: ship5,
  37: ship6,
  38: ship7,
  39: ship8,
  40: ship9,
  41: ship10,
  43: commonBox,
  44: uncommonBox,
  45: rareBox,
  46: barrelCommon,
  47: barrelUncommon,
  71: littleHut,
  72: mediumHut,
  73: largerHut,
  74: greatHut,
  75: speciousHut
};

const complexTokensMainnet: ComplexToken = {
  16: chairCommon,
  17: chairUncommon,
  18: chairRare,
  19: palmCommon,
  20: palmUncommon,
  21: palmRare,
  22: ship1,
  23: ship2,
  24: ship3,
  25: ship4,
  26: ship5,
  27: ship6,
  28: ship7,
  29: ship8,
  30: ship9,
  31: ship10,
  32: woodenPlateAcorns,
  34: commonBox,
  35: uncommonBox,
  36: rareBox,
  37: barrelCommon,
  38: barrelUncommon,
  55: littleHut,
  56: mediumHut,
  57: largerHut,
  58: greatHut,
  59: speciousHut,
  52: woodenPlateLeaves
};

export default tokens;
export { type ComplexToken, complexTokensTestnet, complexTokensMainnet };

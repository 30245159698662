<template>
  <div class="app-wrapper" :class="{ 'ru-locale': $i18n.locale === 'ru' }">
    <NuxtLayout>
      <NuxtPage />
      <el-dialog
        v-model="showPopupValue"
        :title="$t('notificationError')"
        :append-to-body="true"
        :class="{ 'ru-locale': $i18n.locale === 'ru' }"
        @closed="close"
      >
        <div class="error-wrapper">
          {{ error }}
        </div>
        <div class="btns">
          <button class="btn-primary" @click="close">{{ $t('coreClose') }}</button>
        </div>
      </el-dialog>
    </NuxtLayout>
    <RegisterUserModal />
    <GeneralSoundControlsModal />

    <TransactionNotifications :key="notificationUpdateVersion" />
  </div>
</template>

<script lang="ts" setup>
import { useWeb3ModalAccount } from '@web3modal/ethers/vue';
import { apiUrls } from './utils/constants';
import { BigNumber } from 'bignumber.js';
import { ZeroAddress } from 'ethers';
import type { TSeasonConfig } from './types/season';
import type { IUserModel, UserReferralStatus } from '~/types/apiService';

const store = useMainStore();
const { address } = useWeb3ModalAccount();
const { apiUrl, blockchain } = useEnvs();
const { t } = useI18n();
const { getContractReadOnly } = useAbiAccess();
const refId = useCookie('refId');

const claimBtnTokenIcon = ref('');

const seasonContract = await getContractReadOnly('season', blockchain.contracts.season);
const seasonYearContract = await getContractReadOnly(
  'SEASON_REWARDS_YEAR_1',
  blockchain.contracts.SEASON_REWARDS_YEAR_1
);

const currentSeasonId = await seasonContract?.currentSeason();

const position = asyncComputed<number>(async () => {
  if (!BigNumber(store.notificationUpdateVersion).isNaN()) {
    return seasonContract?.getUserPositionInSeason(currentSeasonId, address.value || ZeroAddress, 1000);
  }
}, 0);

const isUnclaimedPositions = asyncComputed(async () => {
  if (BigNumber(store.notificationUpdateVersion).isNaN()) return;
  return await Promise.allSettled(
    new Array(BigNumber(currentSeasonId).toNumber()).fill(null).map(async (id, i) => {
      const positionInSeason = await seasonContract?.getUserPositionInSeason(i, address.value || ZeroAddress, 1000);
      const seasonPrizePositionsCount = await seasonYearContract?.getSeasonRewardsPositionsCount(i);

      return BigNumber(positionInSeason).isNaN() ||
        BigNumber(positionInSeason).isZero() ||
        BigNumber(positionInSeason).isGreaterThan(seasonPrizePositionsCount)
        ? null
        : await seasonYearContract?.isSeasonPositionClaimed(i, positionInSeason);
    })
  ).then((results) =>
    results
      .map((res) => {
        return res.status === 'fulfilled' ? res.value : null;
      })
      .some((item, i) => {
        if (!claimBtnTokenIcon.value) {
          seasonContract
            ?.getUserPositionInSeason(i + 1, address.value || ZeroAddress, 1000)
            .then((pos) => {
              if (!BigNumber(pos).isZero()) {
                return seasonYearContract?.getSeasonPositionConfig(i + 1, pos);
              }
              return null;
            })
            .then((config) => {
              if (config && config.length > 0) {
                claimBtnTokenIcon.value = 'season_chest';
              }
            });
        }
        return typeof item === 'boolean' && !item;
      })
  );
});

provide('currentSeasonId', currentSeasonId);

const { data, execute } = await useFetch<TSeasonConfig>(apiUrls.seasons.config(currentSeasonId), {
  baseURL: apiUrl,
  immediate: false,
  key: 'seasonsConfig',
  watch: [address, () => currentSeasonId, () => store.notificationUpdateVersion]
});

watch(
  () => currentSeasonId,
  () => {
    if (BigNumber(currentSeasonId).isNaN()) return;
    execute();
  },
  { immediate: true }
);

const seasonEndDate = computed(() => {
  const endDate = new Date(data.value?.endDate || new Date());
  if (data.value?.endDate === data.value?.startDate) {
    endDate.setMonth(endDate.getMonth() + 1);
  }
  return endDate;
});

provide('seasonEndDate', seasonEndDate);

const { timeLeft, clearCountdown } = useCountdownDate(seasonEndDate, 'second');
provide('ended', timeLeft.ended);

onBeforeMount(clearCountdown);

const seasonsDaysLeftText = computed(() => {
  if (timeLeft.ended.value) {
    return t('seasonTimeEnded');
  }

  if (timeLeft.days.value === 1) {
    return t('coreDay');
  }

  return t('coreDays');
});

provide('currentSeasonId', currentSeasonId);
provide('playerPosition', position);
provide('seasonEndDate', seasonEndDate);
provide('timeLeft', timeLeft);
provide('seasonsDaysLeftText', seasonsDaysLeftText);
provide('isUnclaimedPositions', isUnclaimedPositions);
provide('claimBtnTokenIcon', claimBtnTokenIcon);
provide('ended', timeLeft.ended);

const error = computed(() => store.error);
const showPopupValue = computed(() => !!store.error);
const notificationUpdateVersion = computed(() => store.notificationUpdateVersion);

const close = () => {
  store.setError('');
};

const { execute: createUser } = useFetch<IUserModel>(apiUrls.v1.user, {
  method: 'POST',
  baseURL: apiUrl,
  body: {
    address,
    pendingReferrerId: refId.value
  },
  immediate: false,
  onResponse: async ({ response }) => {
    if (!response.ok) {
      return;
    }
    await refreshNuxtData('userData');
  }
});

watch(
  [address, refId],
  ([newAddress, newReferrer]) => {
    if (newAddress && newReferrer) createUser();
  },
  { immediate: true }
);

const { data: referralStatus } = useFetch<UserReferralStatus>(`${apiUrls.v1.user}/referral-status`, {
  baseURL: apiUrl,
  watch: [address],
  query: {
    address
  },
  key: 'referralStatus',
  immediate: true,
  server: false
});

watch(referralStatus, (status) => {
  if (!status?.isReferralMember) {
    return;
  }

  store.setIsJoiningToReferral(false);
});

onMounted(() => {
  updateProcessingTransactions();
  store.updateVersion();
});
</script>

<style scoped lang="scss">
.app-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 1px);
}

.error-wrapper {
  margin-bottom: 12px;
  text-align: center;
}
</style>

<!-- TODO: move this global styles to the main scss file -->
<style lang="scss">
.el-dialog.login-popup {
  width: 280px;
}

.modalCls {
  text-align: center;
}

.el-dialog.video-popup {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 700px;
  }
}

.el-dialog.full-body {
  width: 100%;
  max-width: 720px;

  .el-dialog__body {
    max-width: 100%;
  }
}
.el-dialog.buy-popup {
  max-width: 550px;
  min-width: 450px;

  .show-close {
    padding-right: 0;
  }

  @media screen and (max-width: 479px) {
    overflow-x: hidden;
    min-width: unset;
  }
}

.el-dialog.info {
  max-width: 800px;
  padding: 25px;

  .el-dialog__body {
    max-width: unset;
  }
}

.el-dialog.crafting {
  max-width: 1024px;
  width: 100%;
  overflow: visible;

  .el-dialog__body {
    max-width: 100%;
  }

  @media screen and (max-width: 1400px) {
    max-width: 710px;
  }

  @media screen and (max-width: 900px) {
    overflow: auto;
  }
}

.el-dialog.is-fullscreen {
  width: 100%;
  max-width: 100%;
}

.el-dialog.without-header .el-dialog__header {
  height: 0;
  @media screen and (max-width: 900px) {
    height: auto;
  }
}

.el-dialog {
  border-radius: 12px;
  // border: 2px solid var(--main-blue);
  backdrop-filter: blur(5px);
  background-color: rgba(2, 27, 38, 0.8);
  overflow: hidden;
  max-width: 574px;
  box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.4);
  border: 1px solid #17353f;

  &__header {
    text-align: center;
    margin: 0;
    padding: 6px 0 0 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    font-family: Grenze, sans-serif;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.273em;

    @media screen and (max-width: 991px) {
      padding: 4px 0px 0px 30px;
    }

    span {
      max-width: 100%;
    }
  }

  .el-dialog__title {
    font-size: 32px;
    color: #fff;

    @media screen and (max-width: 991px) {
      padding-right: 0px;
      font-size: 30px;
    }
  }

  &__body {
    max-width: 415px;
    margin: auto;
    padding-bottom: 30px;
    padding-top: 30px;
    line-height: 1.5;
  }

  &__close,
  .el-icon svg {
    height: 30px;
    width: 30px;
  }
}

.popup-canceled {
  border: 2px solid var(--canceled-color);
}

.popup-confirm {
  border: 2px solid var(--confirm-color);
}

.el-button {
  display: flex;
  padding: 0 38px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 86px;
  background: var(--main-blue);
  text-align: center;
  font-size: 24px;
  color: var(--main-text-color);
  border: 1px solid transparent;
  height: 47px;

  &.is-fullscreen {
    height: 100dvh;
  }

  &:active,
  &:focus,
  &:hover {
    background: transparent;
    border: 1px solid #939393;
  }
}

.table .el-table {
  --el-table-border-color: none;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.55);
  &:not(.line-rounded-icon, .line-square-icon) {
    font-family: Grenze, sans-serif;
  }

  @media screen and (max-width: 991px) {
    font-size: 14px;
  }
}

.table .el-table tr {
  background-color: transparent;
}

.table .el-table tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.3);
}

.table .el-table th.el-table__cell {
  background: var(--dark-2);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: transparent;
  color: #fff;
  font-family: Grenze, sans-serif;
  font-size: 24px;
  font-weight: 400;

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .el-table__cell {
  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 479px) {
    font-size: 12px;
  }
}

.el-table .cell {
  word-break: normal;
}

.el-table th.el-table__cell:first-child {
  border-radius: 16px 0 0 0;
}

.el-table th.el-table__cell:last-child {
  border-radius: 0 16px 0 0;
}

.el-icon.el-dialog__close svg {
  display: none;
}

.el-dialog__headerbtn {
  height: 20px;
  width: 20px;
  top: 23px;
  right: 20px;
  z-index: 999;
}

.el-icon.el-dialog__close {
  background-image: url('~/public/img/icons/bone_close.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  &:hover {
    opacity: 0.6;
  }
}

.btns {
  display: flex;
  justify-content: center;
}

.el-button + .el-button {
  margin-left: 0 !important;
}

.el-checkbox__label {
  color: var(--second-text-color);
  text-align: left;
  font-family: Grenze, sans-serif;
  white-space: initial;
  line-height: 1.333em;
  font-size: 16px !important;

  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}

.el-checkbox {
  display: flex;
  align-items: flex-start;
  height: auto !important;
}

.el-checkbox__input {
  margin-top: 4px;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--main-text-color);
}

.scene-wrapper {
  width: 100%;
  height: calc(100dvh - 117px);
}

.video-bg > video {
  width: 100%;
  height: auto;
}
</style>

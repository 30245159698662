import type { TokenIconUniquenessType } from '~/public/img/tokens';
import tokens from '~/public/img/tokens';
import type { RecipeProduct } from '~/types/crafting';
import type { TSeasonConfigItem } from '~/types/season';
import { TokenUniquenessType, type Tokens } from '~/utils/constants';

export default defineNuxtPlugin(() => {
  const { blockchain } = useEnvs();

  return {
    provide: {
      getIconToken: (product: RecipeProduct | TSeasonConfigItem, recipeId?: number): string => {
        const productTokenAddress = product.tokenAddress;
        const icon: string | string[] | TokenIconUniquenessType =
          tokens[tokenMetaData(blockchain.contracts, productTokenAddress)?.name?.contract as Tokens] || '';
        const isCollectionIcons = Array.isArray(icon);
        const isSingleIcon = typeof icon === 'string';
        const isUniquenessIcons = !isSingleIcon && ('common' in icon || 'uncommon' in icon || 'rare' in icon);

        if ('pointsMultiplier' in product && !isCollectionIcons && isUniquenessIcons) {
          return icon[TokenUniquenessType.Common];
        }

        if ('productId' in product) {
          const productUniqueness = getNftValueByAddress(blockchain.contracts, product);

          if (productUniqueness && !isCollectionIcons && isUniquenessIcons) {
            return icon[productUniqueness as TokenUniquenessType];
          }
        }

        if (recipeId && isCollectionIcons) {
          return getIcon(recipeId, productTokenAddress);
        }

        return (Array.isArray(icon) ? icon[0] : icon) as string;
      },

      getProductUniqueness: (product: RecipeProduct | undefined) => {
        if (!product) return '';

        return getNftValueByAddress(blockchain.contracts, product);
      },

      getAltAttribute: (product: RecipeProduct): string => {
        const productTokenAddress = product.tokenAddress;
        return tokenMetaData(blockchain.contracts, productTokenAddress).label;
      },

      isShip: (product: RecipeProduct): boolean => {
        return product.tokenAddress === blockchain.contracts.ships.addresses.contract;
      }
    }
  };
});

<template>
  <div>
    <el-dialog
      v-model="store.isShowSoundControlsModal"
      class="settingsModal"
      :class="{ 'ru-locale': $i18n.locale === 'ru' }"
      :title="$t('soundControlsModalTitle')"
      :append-to-body="true"
      :fullscreen="$device.isMobile"
    >
      <div class="settingsTabs">
        <div class="settingTab">
          {{ $t('sound') }}
        </div>
      </div>

      <UIModalInnerContentWrapper style="background-color: rgba(13, 36, 47, 1); padding-top: 5px">
        <GeneralSoundControlItem
          v-for="(item, volumeKey) in store.volume"
          :key="volumeKey"
          v-model="store.volume[volumeKey]"
          :control-name="volumeKey"
        />
      </UIModalInnerContentWrapper>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
const store = useMainStore();
</script>

<style lang="scss" scoped>
.settingsTabs {
  width: 100%;
  margin-bottom: 20px;
}

.settingTab {
  width: 163px;
  height: 40px;
  margin: 0 auto;
  background: linear-gradient(to bottom, rgba(0, 131, 173, 1), rgba(0, 53, 79, 1));
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Grenze, sans-serif;
  font-weight: 600;
  font-size: 24px;
  border-radius: 12px;
}
</style>

<style lang="scss">
.settingsModal {
  &.el-dialog {
    margin: 0;
    position: fixed;
    left: 50%;
    top: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

    .el-dialog__body {
      max-width: 100%;
      padding-top: 15px;
    }
  }
}
</style>
